import React, { useState, useEffect } from 'react';
import {
    Typography,
    TextField,
    FormControl,
    Box,
    Tooltip,
    ButtonGroup,
    Button,
    Alert
} from '@mui/material';
import InstitutionAutocomplete from '../components/institutionAutocomplete';
import AddressSearch from '../components/addressSearch';
import HouseNumberAutocomplete from '../components/houseNumberAutocomplete';
import { useUser } from '../components/userContext';
import InfoIcon from '@mui/icons-material/Info';
import HomeIcon from '@mui/icons-material/Home';
import SearchIcon from '@mui/icons-material/Search';
import TransportDesignBox from '../components/TransportDesignBox';

const FromToStep = ({ formData, setFormData, activeTab, isExpertMode }) => {
    const [selectedTabFrom, setSelectedTabFrom] = useState(0);
    const { user } = useUser(); // Angemeldeter User
    const allowedRoles = ['user', 'dialysis'];
    const isTransportApproved = formData.status === "approved" && allowedRoles.includes(user?.role);
    const [fromAddressWarning, setFromAddressWarning] = useState('');

    const handleInstitutionChange = (name, newValue) => {
        if (newValue) {
            const streetAndNumber = newValue.street_and_number;
            const lastSpaceIndex = streetAndNumber.lastIndexOf(' ');

            const street = streetAndNumber.substring(0, lastSpaceIndex).trim();
            const houseNumber = streetAndNumber.substring(lastSpaceIndex + 1).trim();

            setFormData(prevData => ({
                ...prevData,
                [`${name}_institution`]: newValue,
                [`${name}_institution_json`]: newValue,
                [`${name}_station`]: newValue.station,
                [`${name}_street`]: street,
                [`${name}_house_number`]: houseNumber,
                [`${name}_postal_code`]: newValue.postal_code,
                [`${name}_city`]: newValue.city,
            }));
        } else {
            setFormData(prevData => ({
                ...prevData,
                [`${name}_institution`]: '',
                [`${name}_institution_json`]: null,
                [`${name}_station`]: '',
                [`${name}_street`]: '',
                [`${name}_house_number`]: '',
                [`${name}_postal_code`]: '',
                [`${name}_city`]: '',
            }));
        }
    };

    // Funktion, die aufgerufen wird, wenn eine Adresse aus der Vorschlagsliste ausgewählt wird
    const handleAddressSelect = (selectedAddress) => {
        if (selectedAddress) {
            setFormData((prevData) => ({
                ...prevData,
                from_street: selectedAddress.strasse,
                from_house_number: selectedAddress.hausnummer,
                from_postal_code: selectedAddress.plz,
                from_city: selectedAddress.ort,
                from_institution: null,
                from_station: null,
            }));
        }
    };

    const validateAddress = async (address, setWarning) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URI}/api/validate-address?address=${encodeURIComponent(address)}`, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json', Authorization: user.api_key },
            });
            const data = await response.json();

            if (data.status === 'success') {
                setWarning(''); // Keine Warnung, Adresse validiert
            } else {
                setWarning('Die eingegebene Adresse konnte nicht verifiziert werden. Bitte prüfen.');
            }
        } catch (error) {
            console.error('Fehler bei der Adressvalidierung:', error);
            setWarning('Fehler bei der Validierung. Bitte versuchen Sie es später erneut.');
        }
    };

    useEffect(() => {
        if (formData.from_street && formData.from_city) {
            const fullAddress = `${formData.from_street}, ${formData.from_city}`;
            validateAddress(fullAddress, setFromAddressWarning);
        }
        // eslint-disable-next-line
    }, [formData.from_street, formData.from_city]);

    // Effect to switch to "Intelligente Adresseingabe" when is_private is first checked
    useEffect(() => {
        if (formData.is_private) {
            setSelectedTabFrom(1); // Set to "Intelligente Adresseingabe"
        } else {
            setSelectedTabFrom(0);
        }
    }, [formData.is_private]); // Runs only when `is_private` changes


    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    return (
        <TransportDesignBox isExpertMode={isExpertMode} minHeight={540}>
            <Box display="flex" alignItems="center" justifyContent="flex-start">
                <Typography variant="h6">Startadresse (Abholort)</Typography>
                <Typography variant="body2" color="textSecondary" style={{ marginTop: '4px', marginLeft: '12px', fontStyle: 'italic' }}>Wo soll der Patient abgeholt werden?</Typography>
            </Box>
            <FormControl component="fieldset" margin="normal" fullWidth>

                <ButtonGroup variant="outlined" color="primary" style={{ marginBottom: '26px' }}>
                    <Button
                        onClick={() => setSelectedTabFrom(0)}
                        startIcon={<HomeIcon />}
                        variant={selectedTabFrom === 0 ? 'contained' : 'outlined'}
                    >
                        Institution wählen
                    </Button>
                    <Button
                        onClick={() => setSelectedTabFrom(1)}
                        startIcon={<SearchIcon />}
                        variant={selectedTabFrom === 1 ? 'contained' : 'outlined'}
                    >
                        Intelligente Adresseingabe
                    </Button>
                </ButtonGroup>
                {/* Warnung für ungültige Startadresse */}
                {fromAddressWarning && (
                    <Alert severity="warning" style={{ marginBottom: '12px' }}>
                        {fromAddressWarning}
                    </Alert>
                )}
                {/* Institutionensuche */}
                {selectedTabFrom === 0 && (
                    <>
                        <InstitutionAutocomplete
                            value={formData.from_institution_json || null}
                            onChange={(event, newValue) => handleInstitutionChange('from', newValue)}
                            label="Institution eingeben (Klinik, Alten- oder Pflegeheim, Arztpraxis,...)"
                            disabled={isTransportApproved}
                            style={{ marginTop: '0px' }}
                        />
                        <Typography variant="body2" color="textSecondary" style={{ marginTop: '4px', marginBottom: '28px', fontStyle: 'italic' }}>
                            Wählen Sie eine Institution oder geben Sie die Adresse manuell ein.
                        </Typography>
                    </>
                )}

                {/* Intelligente Adresseingabe */}
                {selectedTabFrom === 1 && (
                    <>
                        {/* Smart Address Search Input */}
                        <AddressSearch
                            onAddressSelect={handleAddressSelect}
                            disabled={isTransportApproved}
                            style={{ marginTop: '10px' }}
                        />
                        <Typography variant="body2" color="textSecondary" style={{ marginTop: '4px', marginBottom: '28px', fontStyle: 'italic' }}>
                            Intelligente Adresseingabe: Steuerzeichen: + für Ort + Straße, ...
                            <Tooltip
                                title="Steuerzeichen: + für Ort + Straße (z.B. '+birkenw weing' für Straßen in bestimmten Orten), # für Hausnummer, * für globale Suche, ? für Synonyme, & für Postleitzahlen">
                                <InfoIcon style={{ marginLeft: '8px', cursor: 'pointer', fontSize: '1rem' }} color="action" />
                            </Tooltip>
                        </Typography>
                    </>
                )}

                <Box display="flex" style={{ marginTop: isExpertMode ? '2px' : '12px' }}>
                    <TextField
                        label="Straße *"
                        name="from_street"
                        value={formData.from_street || ''}
                        onChange={handleChange}
                        margin={isExpertMode ? "dense" : "normal"}
                        size={isExpertMode ? "small" : "normal"}
                        fullWidth
                        style={{ flex: '0 0 70%', marginRight: '8px' }}
                        error={!formData.from_street}
                        helperText={!formData.from_street && (isExpertMode ? '' : 'Von: Straße ist erforderlich')}
                        disabled={isTransportApproved}
                        autoComplete="off"
                    />
                    <HouseNumberAutocomplete
                        fullWidth
                        margin={isExpertMode ? "dense" : "normal"}
                        size={isExpertMode ? "small" : "normal"}
                        style={{ marginTop: isExpertMode ? '8px' : '16px' }}
                        city={formData.from_city}
                        street={formData.from_street}
                        value={formData.from_house_number}
                        isExpertMode={isExpertMode}
                        onChange={(newValue) => setFormData(prev => ({ ...prev, from_house_number: newValue }))}
                    />
                </Box>
                <Box display="flex">
                    <TextField
                        label="Postleitzahl *"
                        name="from_postal_code"
                        value={formData.from_postal_code || ''}
                        onChange={handleChange}
                        margin={isExpertMode ? "dense" : "normal"}
                        size={isExpertMode ? "small" : "normal"}
                        fullWidth
                        style={{ flex: '0 0 30%', marginRight: '8px' }}
                        error={!formData.from_postal_code}
                        helperText={!formData.from_postal_code && (isExpertMode ? '' : 'Von: Postleitzahl ist erforderlich')}
                        disabled={isTransportApproved}
                        autoComplete="off"
                    />
                    <TextField
                        label="Stadt *"
                        name="from_city"
                        value={formData.from_city || ''}
                        onChange={handleChange}
                        margin={isExpertMode ? "dense" : "normal"}
                        size={isExpertMode ? "small" : "normal"}
                        fullWidth
                        style={{ flex: '1 1 auto' }}
                        error={!formData.from_city}
                        helperText={!formData.from_city && (isExpertMode ? '' : 'Von: Stadt ist erforderlich')}
                        disabled={isTransportApproved}
                        autoComplete="off"
                    />
                </Box>
                <TextField
                    label="Station"
                    name="from_station"
                    value={formData.from_station || ''}
                    onChange={handleChange}
                    margin={isExpertMode ? "dense" : "normal"}
                    size={isExpertMode ? "small" : "normal"}
                    fullWidth
                />
                <TextField
                    label="Zimmer"
                    name="from_room"
                    value={formData.from_room || ''}
                    onChange={handleChange}
                    margin={isExpertMode ? "dense" : "normal"}
                    size={isExpertMode ? "small" : "normal"}
                    fullWidth
                />
                <TextField
                    label="Zusätzliche Informationen"
                    name="from_additional_info"
                    value={formData.from_additional_info || ''}
                    onChange={handleChange}
                    margin={isExpertMode ? "dense" : "normal"}
                    size={isExpertMode ? "small" : "normal"}
                    fullWidth
                    multiline
                    rows={3}
                />
            </FormControl>
        </TransportDesignBox>
    );
};

export default FromToStep;
