import React, { useState } from 'react';
import {
    Typography,
    TextField,
    Box,
    Checkbox,
    FormControlLabel
} from '@mui/material';
import { useUser } from '../components/userContext';
import InstitutionAutocomplete from '../components/institutionAutocomplete';
import TransportDesignBox from '../components/TransportDesignBox';

const UserInformationStep = ({ formData, setFormData, isExpertMode }) => {
    const { user } = useUser(); // Angemeldeter User.
    const allowedRoles = ['user', 'dialysis'];
    const isTransportApproved = formData.status === "approved" && allowedRoles.includes(user?.role);

    const [phoneNumberError, setPhoneNumberError] = useState(false); // Für die Validierung der Telefonnummer

    const handleChange = (event) => {
        const { name, value } = event.target;

        // Validierung für die Telefonnummer
        if (name === "phoneNumber") {
            const phoneNumberRegex = /^\+?[0-9\s]*$/; // Erlaubt nur das + Zeichen und Ziffern
            setPhoneNumberError(!phoneNumberRegex.test(value)); // Setzt Fehler auf true, wenn ungültig
        }

        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleCheckboxChange = (event) => {
        setFormData(prevData => ({
            ...prevData,
            is_private: event.target.checked,
            private_first_name: event.target.checked ? '' : null,
            private_last_name: event.target.checked ? '' : null,
            stationName: !event.target.checked ? '' : formData.stationName
        }));
    };

    return (
        <TransportDesignBox isExpertMode={isExpertMode} minHeight={280}>
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <Typography variant="h6">Angaben zum Besteller</Typography>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={formData.is_private || false}
                            onChange={handleCheckboxChange}
                        />
                    }
                    label="Privat"
                />
            </Box>

            {!formData.is_private && user?.role !== 'user' && (
                <InstitutionAutocomplete
                    value={formData.institution_json || null}
                    style={{ marginBottom: isExpertMode ? '10px' : '30px' }}
                    onChange={(event, newValue) => {
                        if (newValue === null) {
                            setFormData({
                                ...formData,
                                institution: '',
                                institution_json: null,
                                objectInstitution: '',
                                stationName: '',
                                phoneNumber: '',
                                from_institution: '',
                                from_station: '',
                                from_room: '',
                                from_street: '',
                                from_house_number: '',
                                from_postal_code: '',
                                from_city: ''
                            });
                        } else {
                            const streetAndNumber = newValue.street_and_number;
                            const lastSpaceIndex = streetAndNumber.lastIndexOf(' ');

                            const street = streetAndNumber.substring(0, lastSpaceIndex).trim();
                            const houseNumber = streetAndNumber.substring(lastSpaceIndex + 1).trim();

                            setFormData({
                                ...formData,
                                institution: newValue,
                                institution_json: newValue,
                                objectInstitution: newValue.object_institution,
                                stationName: newValue.station,
                                phoneNumber: newValue.phone_number || '',
                                from_institution: newValue,
                                from_institution_json: newValue,
                                from_station: newValue.station,
                                from_street: street,
                                from_house_number: houseNumber,
                                from_postal_code: newValue.postal_code,
                                from_city: newValue.city
                            });
                        }
                    }}
                    label="Institution eingeben (Klinik, Alten- oder Pflegeheim, Arztpraxis,...)"
                    error={!formData.objectInstitution}
                    helperText={!formData.objectInstitution && 'Objekt / Einrichtung ist erforderlich'}
                    disabled={isTransportApproved}
                />
            )}

            {formData.is_private ? (
                <>
                    <TextField
                        label="Vorname des Bestellers"
                        name="private_first_name"
                        value={formData.private_first_name || ''}
                        onChange={handleChange}
                        margin={isExpertMode ? "dense" : "normal"}
                        size={isExpertMode ? "small" : "normal"}
                        fullWidth
                        error={!formData.private_first_name}
                        helperText={!formData.private_first_name && 'Vorname ist erforderlich'}
                        disabled={isTransportApproved}
                    />
                    <TextField
                        label="Nachname des Bestellers"
                        name="private_last_name"
                        value={formData.private_last_name || ''}
                        onChange={handleChange}
                        margin={isExpertMode ? "dense" : "normal"}
                        size={isExpertMode ? "small" : "normal"}
                        fullWidth
                        error={!formData.private_last_name}
                        helperText={!formData.private_last_name && 'Nachname ist erforderlich'}
                        disabled={isTransportApproved}
                    />
                </>
            ) : (
                <>
                    {isExpertMode && formData.objectInstitution && (
                        <Box mb={1}>
                            <Typography variant="body2" color="textSecondary">
                                <strong>Objekt / Einrichtung:</strong> {formData.objectInstitution || 'Keine Angabe'}
                            </Typography>
                        </Box>
                    )}
                    <TextField
                        label="Station"
                        name="stationName"
                        value={formData.stationName || ''}
                        onChange={handleChange}
                        margin={isExpertMode ? "dense" : "normal"}
                        size={isExpertMode ? "small" : "normal"}
                        fullWidth
                        style={{ marginTop: isExpertMode ? '24px' : undefined }}
                        error={!formData.stationName}
                        helperText={!formData.stationName && (isExpertMode ? '' : 'Station ist erforderlich')}
                        disabled={isTransportApproved}
                    />
                </>
            )}

            <TextField
                label="Rückrufnummer"
                name="phoneNumber"
                value={formData.phoneNumber || ''}
                onChange={handleChange}
                margin={isExpertMode ? "dense" : "normal"}
                size={isExpertMode ? "small" : "normal"}
                fullWidth
                error={phoneNumberError || !formData.phoneNumber} // Fehler bei ungültiger Nummer oder leerem Feld
                helperText={
                    !formData.phoneNumber
                        ? (isExpertMode ? '' : 'Rückrufnummer ist erforderlich')
                        : phoneNumberError
                            ? (isExpertMode ? '' : 'Ungültige Telefonnummer. Nur "+" und Ziffern erlaubt.')
                            : ''
                }
                disabled={isTransportApproved}
            />
        </TransportDesignBox>
    );
};

export default UserInformationStep;