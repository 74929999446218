import React, { useEffect, useState } from 'react';
import {
    Typography,
    FormControl,
    FormLabel,
    RadioGroup,
    Radio,
    FormControlLabel,
    Box,
    Checkbox
} from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import 'dayjs/locale/de'; // Stellen sicher, dass das deutsche Locale geladen wird
import { useUser } from '../components/userContext';
import BooleanButtonGroup from '../components/buttonGroup';
import TransportDesignBox from '../components/TransportDesignBox';

const WhenStep = ({ formData, setFormData, isExpertMode }) => {
    const [dateError, setDateError] = useState(null);
    const { user } = useUser(); // Angemeldeter User
    const allowedRoles = ['user', 'dialysis'];
    const isTransportApproved = formData.status === "approved" && allowedRoles.includes(user?.role);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const now = dayjs();
    const minDate = dayjs().add(60, 'minutes');
    const maxDate = dayjs().add(10, 'years');

    dayjs.extend(updateLocale);
    // Replace "en" with the name of the locale you want to update.
    dayjs.updateLocale('de', {
        // Sunday = 0, Monday = 1.
        weekStart: 1,
    });

    useEffect(() => {
        dayjs.updateLocale('de', {
            weekStart: 1,
        });
    }, []); // Nur einmal beim ersten Mount ausführen

    const errorMessage = React.useMemo(() => {
        const isDepartureDateTimeValid = dayjs(formData.departureDateTime).isBetween(now, minDate, null, '[]'); // '[]' bedeutet inklusiv, d.h., die Grenzen sind eingeschlossen

        switch (dateError) {
            case 'maxDate':
            case 'minDate':
            case 'minDateTime':
                return 'Ungültiges Datum. Bitte prüfen...';

            case 'minTime':
                if (isDepartureDateTimeValid) {
                    return 'Abfahrstdatum zu knapp. Bitte bedenken Sie, dass wir etwas Vorlaufzeit benötigen.';
                } else {
                    return 'Abfahrstdatum liegt in der Vergangenheit. Bitte prüfen...';
                }

            case 'disablePast':
                return 'Abfahrstdatum liegt in der Vergangenheit. Bitte prüfen...';

            case 'invalidDate':
                return 'Datum nicht korrekt';

            default:
                return '';
        }
        // eslint-disable-next-line
    }, [dateError]);

    return (
        <TransportDesignBox isExpertMode={isExpertMode} minHeight={280}>
            <Typography variant="h6">Wann soll der Transport durchgeführt werden?</Typography>
            <FormControl component="fieldset" margin="normal" fullWidth>
                <FormLabel component="legend" style={{ color: formData.transport_type ? 'inherit' : '#E61F02' }}>
                    Art des Transports
                </FormLabel>
                <RadioGroup
                    row
                    aria-label="transport_type"
                    name="transport_type"
                    value={formData.transport_type || ''}
                    onChange={handleChange}
                    disabled={isTransportApproved}
                >
                    {['TERMINFAHRT', 'ENTLASSUNG', 'EINWEISUNG', 'VERLEGUNG'].map((type) => (
                        <FormControlLabel
                            key={type}
                            value={type}
                            style={{ color: formData.hasOwnProperty('transport_type') ? 'inherit' : '#E61F02' }}
                            control={
                                <Radio
                                    tabIndex={0} // Macht den Radio-Button fokussierbar
                                    onKeyDown={(event) => {
                                        if (event.key === 'Enter') {
                                            handleChange({ target: { name: 'transport_type', value: type } });
                                        }
                                    }}
                                />
                            }
                            label={
                                type === 'TERMINFAHRT' ? 'Terminfahrt' :
                                    type === 'ENTLASSUNG' ? 'Entlassung' :
                                        type === 'EINWEISUNG' ? 'Einweisung' : 'Verlegung'
                            }
                            disabled={isTransportApproved}
                        />
                    ))}
                </RadioGroup>
            </FormControl>
            <Box mt={isExpertMode ? 2 : 4} display="flex" alignItems="center">
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
                    <DateTimePicker
                        label={formData.transport_type === 'TERMINFAHRT' ? 'Ankunft Zielort' : 'Wunschzeit Abfahrt'}
                        name="departureDateTime"
                        value={formData.departureDateTime || null}
                        onChange={(date) => handleChange({ target: { name: 'departureDateTime', value: date } })}
                        margin="normal"
                        fullWidth
                        ampm={false}
                        format="DD.MM.YYYY HH:mm"
                        disablePast
                        maxDate={maxDate}
                        minDateTime={minDate}
                        disabled={isTransportApproved}
                        onError={(newError) => setDateError(newError)}
                        slotProps={{
                            textField: {
                                error: dateError || !formData.departureDateTime, // Set error prop
                                helperText: errorMessage, // Set helper text
                            },
                        }}
                    />
                </LocalizationProvider>

                {isExpertMode && (formData.transport_type === 'TERMINFAHRT') && (
                    <Box ml={4}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formData.ambulatory || false}
                                    onChange={(event) => setFormData(prev => ({ ...prev, ambulatory: event.target.checked }))}
                                    onKeyDown={(event) => {
                                        // Überprüfen, ob die Enter-Taste gedrückt wurde
                                        if (event.key === 'Enter') {
                                            event.preventDefault(); // Verhindert das Standardverhalten
                                            setFormData(prev => ({ ...prev, ambulatory: !prev.ambulatory }));
                                        }
                                    }}
                                    name="ambulatory"
                                    color="primary"
                                    tabIndex={0} // Fokussierbar machen
                                />
                            }
                            label="Rückfahrt hinzufügen"
                            labelPlacement="end"
                        />
                    </Box>
                )}
            </Box>

            {!isExpertMode && (formData.transport_type === 'TERMINFAHRT') && (
                <Box mt={2}>
                    <BooleanButtonGroup
                        fieldName="ambulatory"
                        label="Rückfahrt hinzufügen?"
                        options={[
                            { label: 'Ja', value: true },
                            { label: 'Nein', value: false },
                        ]}
                        formData={formData}
                        setFormData={setFormData}
                        customLabelStyle={{ width: '200px', marginRight: '2px' }}
                    />
                </Box>
            )}
        </TransportDesignBox>
    );
};

export default WhenStep;