import React, { useEffect, useState } from 'react';
import {
    Typography,
    FormControl,
    FormLabel,
    RadioGroup,
    Radio,
    FormControlLabel,
    Box,
    Switch,
    Grid,
    TextField,
    Checkbox,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
} from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import 'dayjs/locale/de'; // Stellen sicher, dass das deutsche Locale geladen wird
import { useUser } from '../components/userContext';
import TransportDesignBox from '../components/TransportDesignBox';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { useTheme } from '@mui/material/styles';

const WhenExpertStep = ({ formData, setFormData, isExpertMode, isEditMode }) => {
    const [dateError, setDateError] = useState(null);
    const { user } = useUser(); // Angemeldeter User
    const allowedRoles = ['user', 'dialysis'];
    const isTransportApproved = formData.status === "approved" && allowedRoles.includes(user?.role);
    const [dialogOpen, setDialogOpen] = useState(false);
    const theme = useTheme();

    const handleOpenDialog = () => {
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const now = dayjs();
    const minDate = dayjs().add(60, 'minutes');
    const maxDate = dayjs().add(10, 'years');

    dayjs.extend(updateLocale);
    // Replace "en" with the name of the locale you want to update.
    dayjs.updateLocale('de', {
        // Sunday = 0, Monday = 1.
        weekStart: 1,
    });

    useEffect(() => {
        if (process.env.NODE_ENV === 'development') {
            console.log('Aktuelle Werte von formData nach Aktualisierung:', formData);
        }
    }, [formData]);

    // Prüfung auf das Datum 2099-31-12
    const DEFAULT_THRESHOLD_DATE = dayjs('2090-01-01');

    const checkIfHeadOfSeries = () => {
        const departureDateTime = dayjs(formData.departureDateTime);

        return departureDateTime.isAfter(DEFAULT_THRESHOLD_DATE, 'day');
    };

    useEffect(() => {
        const isHead = checkIfHeadOfSeries();
        //console.log('checkIfHeadOfSeries: isHead: ', isHead);
        //console.log('checkIfHeadOfSeries:  departureDateTime: ', formData.departureDateTime);

        setFormData((prevData) => ({
            ...prevData,
            isHeadOfSeries: isHead,
        }));
        // eslint-disable-next-line
    }, [formData.departureDateTime]);

    const errorMessage = React.useMemo(() => {
        const isDepartureDateTimeValid = dayjs(formData.departureDateTime).isBetween(now, minDate, null, '[]'); // '[]' bedeutet inklusiv, d.h., die Grenzen sind eingeschlossen

        switch (dateError) {
            case 'maxDate':
            case 'minDate':
            case 'minDateTime':
                return 'Ungültiges Datum. Bitte prüfen...';

            case 'minTime':
                if (isDepartureDateTimeValid) {
                    return 'Abfahrstdatum zu knapp. Bitte bedenken Sie, dass wir etwas Vorlaufzeit benötigen.';
                } else {
                    return 'Abfahrstdatum liegt in der Vergangenheit. Bitte prüfen...';
                }

            case 'disablePast':
                return 'Abfahrstdatum liegt in der Vergangenheit. Bitte prüfen...';

            case 'invalidDate':
                return 'Datum nicht korrekt';

            default:
                return '';
        }
        // eslint-disable-next-line
    }, [dateError]);

    // Berechnet die Standard-Rückfahrtzeit basierend auf der Hinfahrtzeit + 4 Stunden
    const getDefaultReturnTime = () => {
        return formData.departureDateTime ? dayjs(formData.departureDateTime).add(4, 'hours') : null;
    };

    // Minimumzeit für die Rückfahrt (2 Stunden nach der Hinfahrt)
    const getMinReturnTime = () => {
        return formData.departureDateTime ? dayjs(formData.departureDateTime).add(2, 'hours') : minDate;
    };

    const handleRecurringChange = (event) => {
        setFormData((prevData) => ({
            ...prevData,
            is_recurring: event.target.checked,
        }));
    };

    const handleInitalTripChange = (event) => {
        setFormData((prevData) => ({
            ...prevData,
            initial_trip: event.target.checked,
        }));
    };

    const handleReturnTripChange = (event) => {
        const checked = event.target.checked;
        setFormData((prevData) => ({
            ...prevData,
            return_trip: checked,
            // Setzt die Standardzeit auf 4 Stunden nach der Hinfahrt, falls Rückfahrt aktiviert wird
            returnDepartureTime: checked ? getDefaultReturnTime() : null,
        }));
    };

    const handleDayChange = (event, day) => {
        setFormData((prevData) => ({
            ...prevData,
            recurring_days: {
                ...prevData.recurring_days,
                [day]: event.target.checked,
            },
        }));
    };

    const handleReturnDayChange = (event, day) => {
        setFormData((prevData) => ({
            ...prevData,
            recurring_return_days: {
                ...prevData.recurring_return_days,
                [day]: event.target.checked,
            },
        }));
    };

    const handleTimeChange = (day, time) => {
        // Überprüfen, ob eine gültige Uhrzeit eingegeben wurde
        if (time && time.trim() !== "") {
            setFormData((prevData) => ({
                ...prevData,
                recurring_times: {
                    ...prevData.recurring_times,
                    [day]: time,
                },
            }));
        } else {
            // Gib eine Fehlermeldung oder Warnung aus, wenn keine gültige Uhrzeit eingegeben wurde
            alert(`Bitte geben Sie eine gültige Uhrzeit für ${day} ein.`);
        }
    };

    const handleTimeBlur = (event, day) => {
        handleTimeChange(day, event.target.value);
    };

    const handleReturnTimeChange = (day, time) => {
        // Überprüfen, ob eine gültige Uhrzeit eingegeben wurde
        if (time && time.trim() !== "") {
            setFormData((prevData) => ({
                ...prevData,
                recurring_return_times: {
                    ...prevData.recurring_return_times,
                    [day]: time,
                },
            }));
        } else {
            // Gib eine Fehlermeldung oder Warnung aus, wenn keine gültige Uhrzeit eingegeben wurde
            alert(`Bitte geben Sie eine gültige Uhrzeit für ${day} ein.`);
        }
    };

    const handleReturnTimeBlur = (event, day) => {
        handleReturnTimeChange(day, event.target.value);
    };

    const getRecurringSummary = (days, times) => {
        const daysAndTimes = [];
        for (const [day, isSelected] of Object.entries(days || {})) {
            if (isSelected) {
                const time = times?.[day] || 'Keine Zeit angegeben';
                daysAndTimes.push(`${day}: ${time}`);
            }
        }
        return daysAndTimes.length > 0
            ? daysAndTimes.join(', ')
            : 'Keine Tage oder Zeiten ausgewählt';
    };

    const noCheckboxChecked = !formData.initial_trip && !formData.return_trip;
    const recurringError = formData.is_recurring && (!formData.recurring_days || Object.keys(formData.recurring_days).length === 0);
    const isRecurring = formData.is_recurring || formData.is_recurring_return || false;

    return (
        <TransportDesignBox isExpertMode={isExpertMode} minHeight={280}>
            <Typography variant="h6">Wann soll der Transport durchgeführt werden?</Typography>
            <FormControl component="fieldset" margin="normal" fullWidth>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    {/* */}
                    <FormLabel component="legend" style={{ color: formData.transport_type ? 'inherit' : '#E61F02' }}>
                        Art des Transports
                    </FormLabel>
                    <RadioGroup
                        row
                        aria-label="transport_type"
                        name="transport_type"
                        value={formData.transport_type || ''}
                        onChange={handleChange}
                        disabled={isTransportApproved || formData.isHeadOfSeries}
                    >
                        {['TERMINFAHRT', 'ENTLASSUNG', 'EINWEISUNG', 'VERLEGUNG', 'DIALYSEFAHRT'].map((type) => (
                            <FormControlLabel
                                key={type}
                                value={type}
                                style={{ color: formData.hasOwnProperty('transport_type') ? 'inherit' : '#E61F02' }}
                                control={
                                    <Radio
                                        tabIndex={0} // Macht den Radio-Button fokussierbar
                                        onKeyDown={(event) => {
                                            if (event.key === 'Enter') {
                                                handleChange({ target: { name: 'transport_type', value: type } });
                                            }
                                        }}
                                        disabled={isTransportApproved || formData.isHeadOfSeries}
                                    />
                                }
                                label={
                                    type === 'TERMINFAHRT' ? 'Terminfahrt' :
                                        type === 'ENTLASSUNG' ? 'Entlassung' :
                                            type === 'EINWEISUNG' ? 'Einweisung' :
                                                type === 'VERLEGUNG' ? 'Verlegung' : 'Dialysefahrt'
                                }
                                disabled={isTransportApproved || formData.isHeadOfSeries}
                            />
                        ))}
                    </RadioGroup>
                </Box>
            </FormControl>
            {formData.transport_type === 'DIALYSEFAHRT' && (!isEditMode || formData.isHeadOfSeries) && (
                <Box mt={2}>
                    <FormControl component="fieldset">
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                            <FormControlLabel
                                control={<Switch
                                    checked={isRecurring}
                                    onChange={handleRecurringChange}
                                    disabled={isEditMode}
                                />}
                                label="Regelmäßiger Transport?"
                                labelPlacement="start" // Platzierung des Labels links vom Switch
                                sx={{ marginLeft: 0 }}
                            />
                            {/* Link/Button zum Öffnen des Dialogs */}
                            {isRecurring && (
                                <Typography
                                    sx={{ marginLeft: '22px', color: theme.palette.primary.main, cursor: 'pointer', textDecoration: 'underline' }}
                                    onClick={handleOpenDialog}
                                >
                                    Einstellungen bearbeiten
                                </Typography>
                            )}
                        </Box>
                    </FormControl>
                    {/* Fehlermeldung anzeigen */}
                    {recurringError && (
                        <Typography variant="body2" color="#E61F02" sx={{ mt: 1 }}>
                            Bitte wählen Sie mindestens einen Tag für den regelmäßigen Transport aus.
                        </Typography>
                    )}
                    {!recurringError && isRecurring && (
                        <Box mt={2}>
                            <Typography variant="body2" sx={{ fontStyle: 'italic', color: 'gray' }}>
                                Hinfahrten: {getRecurringSummary(formData.recurring_days, formData.recurring_times)}
                            </Typography>
                            <Typography variant="body2" sx={{ fontStyle: 'italic', color: 'gray' }}>
                                Rückfahrten: {getRecurringSummary(formData.recurring_return_days, formData.recurring_return_times)}
                            </Typography>
                        </Box>
                    )}
                </Box>
            )}
            <Box mt={isExpertMode ? 1 : 4} display="flex" alignItems="center">
                {!isRecurring && formData.transport_type === 'DIALYSEFAHRT' && (
                    <Box ml={0} mt={2}>
                        {!isEditMode && (
                            <>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={formData.initial_trip || false}
                                            onChange={handleInitalTripChange}
                                            onKeyDown={(event) => {
                                                // Überprüfen, ob die Enter-Taste gedrückt wurde
                                                if (event.key === 'Enter') {
                                                    event.preventDefault(); // Verhindert das Standardverhalten
                                                    setFormData(prev => ({ ...prev, initial_trip: !prev.initial_trip }));
                                                }
                                            }}
                                            name="initialTrip"
                                            color="primary"
                                            tabIndex={0} // Fokussierbar machen
                                            sx={{ marginLeft: '10px' }}
                                        />
                                    }
                                    label="Hinfahrt (ja/nein)"
                                    labelPlacement="start" // Platzierung des Labels links vom Switch
                                    sx={{
                                        marginLeft: 0,
                                        color: noCheckboxChecked ? '#E61F02' : 'inherit', // Rot wenn keine Checkbox ausgewählt
                                    }}
                                />

                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
                                    <DateTimePicker
                                        label="Ankunft Zielort"
                                        name="departureDateTimeDia"
                                        value={formData.departureDateTime || null}
                                        onChange={(date) => handleChange({ target: { name: 'departureDateTime', value: date } })}
                                        margin="normal"
                                        fullWidth
                                        ampm={false}
                                        format="DD.MM.YYYY HH:mm"
                                        disablePast
                                        maxDate={maxDate}
                                        minDateTime={minDate}
                                        disabled={!isEditMode && (isTransportApproved || !formData.initial_trip)}
                                        onError={(newError) => setDateError(newError)}
                                        slotProps={{
                                            textField: {
                                                disabled: !isEditMode && (isTransportApproved || !formData.initial_trip), // Gleiche Bedingung für das TextField
                                                error: !isEditMode && !isTransportApproved && formData.initial_trip && (dateError || !formData.departureDateTime),
                                                helperText: (!isEditMode && !isTransportApproved && formData.initial_trip && errorMessage) || "", // Helper Text nur anzeigen, wenn aktiv
                                            },
                                        }}
                                        sx={{ marginLeft: '16px' }}
                                    />
                                </LocalizationProvider>
                            </>
                        )}
                        <br />
                        {!isEditMode && (
                            <>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={formData.return_trip || false}
                                            onChange={handleReturnTripChange}
                                            onKeyDown={(event) => {
                                                // Überprüfen, ob die Enter-Taste gedrückt wurde
                                                if (event.key === 'Enter') {
                                                    event.preventDefault(); // Verhindert das Standardverhalten
                                                    setFormData(prev => ({ ...prev, return_trip: !prev.return_trip }));
                                                }
                                            }}
                                            name="returnTrip"
                                            color="primary"
                                            tabIndex={0} // Fokussierbar machen
                                        />
                                    }
                                    label="Rückfahrt (ja/nein)"
                                    labelPlacement="start"
                                    sx={{
                                        marginLeft: 0,
                                        marginTop: 1,
                                        color: noCheckboxChecked ? '#E61F02' : 'inherit', // Rot wenn keine Checkbox ausgewählt
                                    }}
                                />
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
                                    <DateTimePicker
                                        label="Wunschzeit Abfahrt"
                                        name="returnDepartureTime"
                                        value={formData.returnDepartureTime || getDefaultReturnTime()}
                                        onChange={(date) => handleChange({ target: { name: 'returnDepartureTime', value: date } })}
                                        margin="normal"
                                        fullWidth
                                        ampm={false}
                                        format="DD.MM.YYYY HH:mm"
                                        disablePast
                                        maxDate={maxDate}
                                        minDateTime={getMinReturnTime()}
                                        disabled={isTransportApproved || !formData.return_trip}
                                        onError={(newError) => setDateError(newError)}
                                        slotProps={{
                                            textField: {
                                                disabled: isTransportApproved || !formData.return_trip, // TextField deaktivieren
                                                error: !isTransportApproved && formData.return_trip && (dateError || !formData.returnDepartureTime), // Fehler nur anzeigen, wenn aktiv
                                                helperText: (!isTransportApproved && formData.return_trip && errorMessage) || "", // Helfertext nur anzeigen, wenn aktiv
                                            },
                                        }}
                                        sx={{ marginLeft: '15px', marginTop: 1 }}
                                    />
                                </LocalizationProvider>
                                {/* Hinweis, wenn keine Checkbox ausgewählt */}
                                {noCheckboxChecked && (
                                    <Typography variant="body2" color="#E61F02" sx={{ mt: 1 }}>
                                        Bitte wählen Sie mindestens eine Option aus (Hinfahrt oder Rückfahrt).
                                    </Typography>
                                )}
                            </>
                        )}
                    </Box>
                )}
                {
                    (formData.transport_type === 'TERMINFAHRT' || formData.transport_type === 'ENTLASSUNG' || formData.transport_type === 'EINWEISUNG' || formData.transport_type === 'VERLEGUNG'
                        || (formData.transport_type === 'DIALYSEFAHRT' && isEditMode && !formData.isHeadOfSeries)) && (
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
                            <DateTimePicker
                                label={formData.transport_type === 'TERMINFAHRT' ? 'Ankunft Zielort' : 'Wunschzeit Abfahrt'}
                                name="departureDateTime"
                                value={formData.departureDateTime || null}
                                onChange={(date) => handleChange({ target: { name: 'departureDateTime', value: date } })}
                                margin="normal"
                                fullWidth
                                ampm={false}
                                format="DD.MM.YYYY HH:mm"
                                disablePast
                                maxDate={maxDate}
                                minDateTime={minDate}
                                disabled={isTransportApproved}
                                onError={(newError) => setDateError(newError)}
                                slotProps={{
                                    textField: {
                                        disabled: isTransportApproved, // TextField deaktivieren
                                        error: !isTransportApproved && formData.initial_trip && (dateError || !formData.departureDateTime), // Fehler nur anzeigen, wenn aktiv
                                        helperText: (!isTransportApproved && formData.initial_trip && errorMessage) || "", // Helfertext nur anzeigen, wenn aktiv
                                    },
                                }}
                                sx={{ marginLeft: '16px' }}
                            />
                        </LocalizationProvider>
                    )}
                {(formData.transport_type === 'TERMINFAHRT') && (
                    <Box ml={4}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={formData.ambulatory || false}
                                    onChange={(event) => setFormData(prev => ({ ...prev, ambulatory: event.target.checked }))}
                                    onKeyDown={(event) => {
                                        // Überprüfen, ob die Enter-Taste gedrückt wurde
                                        if (event.key === 'Enter') {
                                            event.preventDefault(); // Verhindert das Standardverhalten
                                            setFormData(prev => ({ ...prev, ambulatory: !prev.ambulatory }));
                                        }
                                    }}
                                    name="ambulatory"
                                    color="primary"
                                    tabIndex={0} // Fokussierbar machen
                                />
                            }
                            label="Rückfahrt hinzufügen"
                            labelPlacement="end"
                        />
                    </Box>
                )}
            </Box>
            <>
                {/* Fullscreen Dialog */}
                <Dialog
                    open={dialogOpen}
                    onClose={handleCloseDialog}
                    fullWidth
                    maxWidth="lg"
                    sx={{
                        '& .MuiDialog-paper': {
                            width: '100%',
                            maxWidth: '1200px',
                        },
                    }}
                >
                    <DialogTitle>
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                            <Typography variant="h6">Einstellungen</Typography>
                            <IconButton
                                edge="end"
                                color="primary"
                                onClick={() => {
                                    // Füge hier deine Speichern-Logik hinzu
                                    console.log('Einstellungen gespeichert.');
                                    handleCloseDialog();
                                }}
                                aria-label="save"
                                sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                            >
                                <SaveAltIcon /> {/* Fallback falls später ein Icon benötigt wird */}
                                <Typography variant="button" display="block">
                                    Speichern
                                </Typography>
                            </IconButton>
                        </Box>
                    </DialogTitle>

                    <DialogContent>
                        <Box mt={2}>
                            {/* Checkbox für regelmäßige Hinfahrten */}
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={formData.is_recurring || false}
                                        onChange={(event) => setFormData((prevData) => ({
                                            ...prevData,
                                            is_recurring: event.target.checked,
                                        }))}
                                    />
                                }
                                label="Regelmäßige Hinfahrten?"
                                labelPlacement="end"
                            />
                            <Grid container spacing={2}>
                                {['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'].map(day => (
                                    <Grid item xs={12} sm={4} key={day}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={!!formData.recurring_days?.[day]}
                                                    disabled={!formData.is_recurring}
                                                    onChange={(event) => handleDayChange(event, day)}
                                                />
                                            }
                                            label={day}
                                        />
                                        <TextField
                                            label="Uhrzeit"
                                            type="time"
                                            required
                                            disabled={!formData.is_recurring || !formData.recurring_days?.[day]}
                                            value={formData.recurring_times?.[day] || ''}
                                            onChange={(event) => handleTimeChange(day, event.target.value)}
                                            onBlur={(event) => handleTimeBlur(event, day)}
                                            InputLabelProps={{ shrink: true }}
                                            inputProps={{ step: 300 }} // 5 min
                                            fullWidth
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                            {/* Checkbox für regelmäßige Rückfahrten */}
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={formData.is_recurring_return || false}
                                        onChange={(event) => setFormData((prevData) => ({
                                            ...prevData,
                                            is_recurring_return: event.target.checked,
                                        }))}
                                    />
                                }
                                label="Regelmäßige Rückfahrten?"
                                labelPlacement="end"
                                sx={{ marginTop: 4 }}
                            />
                            <Grid container spacing={2}>
                                {['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'].map((day) => (
                                    <Grid item xs={12} sm={4} key={`return-${day}`}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={!!formData.recurring_return_days?.[day]}
                                                    disabled={!formData.is_recurring_return}
                                                    onChange={(event) => handleReturnDayChange(event, day)}
                                                />
                                            }
                                            label={day}
                                        />
                                        <TextField
                                            label="Rückfahrtzeit"
                                            type="time"
                                            value={formData.recurring_return_times?.[day] || ''}
                                            disabled={!formData.is_recurring_return || !formData.recurring_return_days?.[day]}
                                            onChange={(event) => handleReturnTimeChange(day, event.target.value)}
                                            onBlur={(event) => handleReturnTimeBlur(event, day)}
                                            InputLabelProps={{ shrink: true }}
                                            inputProps={{ step: 300 }} // 5 Minuten
                                            fullWidth
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>
                    </DialogContent>
                </Dialog>
            </>
        </TransportDesignBox>
    );
};

export default WhenExpertStep;